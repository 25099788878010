import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { groq } from 'next-sanity'
import { getClient } from '../utils/sanity'
import LandingPage from '../components/LandingPage'
import { isProduction } from '../utils/envUtils'

const frontPageQuery = groq`
*[_id == "global-config"][0]{
  frontpage -> {
    ...,
    template->{slug},
    content[] {
      ...,
      cta {
        ...,
        route->
      },
      ctas[] {
        ...,
        route->
      }
    }
  }
}
`

const IndexPage = ({ config = {}, ...page }) => {
  useEffect(() => {
    window.location.href = isProduction()
      ? process.env.NEXT_PUBLIC_PROD_SHOPIFY_BASE_URL
      : process.env.NEXT_PUBLIC_DEV_SHOPIFY_BASE_URL
  }, [])

  return <LandingPage page={page} config={config} hideFooter hideHeader />
}

IndexPage.propTypes = {
  config: PropTypes.any,
  page: PropTypes.any
}

export async function getStaticProps({ params = {}, preview = false }) {
  let response = await getClient(preview).fetch(frontPageQuery)
  return {
    props: { preview, ...response?.frontpage }
  }
}

export default IndexPage
